import Logo_2 from "./assets/footer_logo.svg";
import Logo_1 from "./assets/navbar_logo.svg";
import Background from "./assets/Hero_Background.png";
import Hero_Foreground from "./assets/Hero_Foreground.png";
import Product_1 from "./assets/Product_1.png";
import Product_2 from "./assets/Product_2.png";
import Product_3 from "./assets/Product_3.png";
import Product_4 from "./assets/Product_4.png";
import Product_5 from "./assets/Product_5.png";

import About_1_Background from "./assets/About_1_Background.jpg";
import About_1_Foreground from "./assets/About_1_Foreground.png";
import Description_1_Background from "./assets/Description_1_Background.jpg";
import Description_1_Foreground from "./assets/Description_1_Foreground.png";
import Sustainability_1_Background from "./assets/Sustainability_1_Background.jpg";
import Sustainability_1_Foreground from "./assets/Sustainability_1_Foreground.png";

import Certification_1 from "./assets/Certifications_1.png";
import Certification_2 from "./assets/Certifications_2.png";
import Certification_3 from "./assets/Certifications_3.png";
import Certification_4 from "./assets/Certifications_4.png";
import Certification_5 from "./assets/Certifications_5.png";
import Certification_6 from "./assets/Certifications_6.png";

import About_1 from "./assets/About_1.png";
// import About_2 from "./assets/About_2.png";
// import About_3 from "./assets/About_3.png";

import artisans from "./assets/artisans.png";
import sustainability from "./assets/sustainability.png";

const data = {
  companyName: "Amerindian",
  footerCompany: "Sourcewiz",
  mainText: "Delighting each corner and surface of your home",
  subText:
    "Boasting a fine collection of Sheesham, Rosewood & Mangowood home decor, and kitchenware items. Add to the elegance of your living spaces with timeless designs crafted by India’s most skilled artisans.",
  productSectionTitle: "Our Product",
  brandsTitle: "Brands we work with",
  certificationsTitle: "Compliances and Certifications",
  buttonContent: "Send an enquiry",
  nav_logo: Logo_1,
  footer_logo: Logo_2,
  background: Background,
  foreground: Hero_Foreground,
  note: "Note : Size and Designs can be customized for all the products",
  about: {
    heading: "About us",
    mainText:
      "AMERINDIAN is a leading lifestyle home decor and accent furniture manufacturing export house. It has risen as a renowned brand by fusing international tastes with authentic Indian craftsmanship.     Our products have impeccable detailing and design that goes into the making of our extensive variety of wooden boxes, candle holders, paper racks, coaster sets, wooden jewellery, door handles, curtain holders, nautical tables, photo frames, kitchenware, and much more.",
    aboutBack: About_1_Background,
    aboutFore: About_1_Foreground,
  },
  description: {
    heading: "Our Process",
    mainText: `We turn logs of premium quality Sheesham wood (density of nearly 881.83 Kg/m3) into planks. We season the planks for several weeks to attain the right density and fine luster in the moisture-free planks. With dedication and great expertise, our craftsmen put in hundreds of laborious hours to give shape and strength to our nautical gifts and handicraft items. With modern machinery to add final touches and synthetic glue to prevent insecticides, we deliver the highest levels of satisfaction to all our customers.`,
    descBack: Description_1_Background,
    descFore: Description_1_Foreground,
  },

  sustainability: {
    heading: "Charity and Welfare of Artisans",
    mainText:
      "Our artisans are dedicated to creating world-class homeware, kitchenware, marine, and nautical instruments with finesse and opulence. To elevate the Indian handicraft artisans, we create avenues to upscale the local market economy. We provide best-in-class technology for propelling their growth ahead. Our online store features the work of artisans from rural areas who work one bead at a time to create incredible works of art. And 1% of all our proceeds go directly to NGOs that provide education to children in rural areas and look after their well-being.",
    sustainabilityFore: Sustainability_1_Foreground,
    sustainabilityBack: Sustainability_1_Background,
  },

  prodLinkArray: [
    {
      title: "Rugs",
      link: "/#",
    },
    {
      title: "Pillows",
      link: "",
    },
    {
      title: "Poufs",
      link: "",
    },
    {
      title: "Wall hangers",
      link: "/#",
    },
  ],

  Elements: [
    {
      link: "/#home",
      title: "Home",
    },
    {
      link: "/#products",
      title: "Products",
    },
    {
      link: "/#about",
      title: "About us",
    },
  ],

  brandArray: [
    {
      title: "Company 1",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 2",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 3",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 4",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 5",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 6",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 7",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 8",
      link: "/#",
      image: Background,
    },
  ],
  prodArray: [
    { title: "Spectacles Holder", image: Product_1, link: "" },
    {
      title: "Jewelry Box",
      image: Product_2,
      link: "",
    },
    {
      title: "Tray",
      image: Product_3,
      link: "",
    },
    {
      title: "Wall Cross",
      image: Product_4,
      link: "",
    },
    {
      title: "Beach Towels",
      image: Product_5,
      link: "",
    },
  ],
  imgArray: [
    {
      image: Certification_1,
    },
    {
      image: Certification_2,
    },
    {
      image: Certification_3,
    },
    {
      image: Certification_4,
    },
    {
      image: Certification_5,
    },
    {
      image: Certification_6,
    },
  ],

  aboutData: [
    {
      image: About_1,
    },
    // {
    //   image: About_2,
    // },
    // {
    //   image: About_3,
    // },
  ],

  sustainabilityData: {
    title: "Sustainability is the way of life, the way of our manufacturing",
    text: "",
    image: sustainability,
  },

  artisansData: {
    title:
      "The artisans are the masters behind creating our world class products",
    text: "",
    image: artisans,
  },

  emails: ["amerindiann@gmail.com"],
};

export default data;
