import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  flexDirection: "column",
  width: "100vw",
  zIndex: -1,
  backgroundColor: "$footerBg",
  color: "$white",
  padding: "1.5em 0 0 1em",
  "@media only screen and (max-width : 600px)": {
    alignItems: "flex-start",
    padding: "1em",
  },

  "@media only screen and (max-width : 1600px)" : {
    width : "100vw",

  }
});

export const Content = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  lineHeight: "2em",
  "@media only screen and (max-width : 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "left",
  },
});
export const Copyright = styled("div", {
  color: "#B4B7B6",
  width: "100%",
  textAlign: "center",
  background: "#1D2624",
  color: "white",
  height: "25px",
  fontSize: "12px",
  paddingBottom: "20px",
  backgroundColor: "$footerBg",
});

export const Box = styled("div", {
  margin: "3em 2em",
  "@media only screen and (max-width : 600px)": {
    margin: "1em 1em",
  },
});

export const CompanyCred = styled("div", {
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const Header = styled("h1", {
  margin: "1em 00.5em",
  "@media only screen and (max-width : 600px)": {
    fontSize: "$4",
    margin: "1em 0em",
  },
});

export const Text = styled("p", {
  margin: "1em 0",
  color: "#cccccc",
});

export const BoldText = styled("p", {
  margin: "1em 0",
  fontWeight: "600",
  fontFamily: "$secondary",
  fontSize : '$1',
});

export const ListText = styled("a", {
  margin: "1em 2em 1em 0",
  display: "block",
  fontFamily: "$secondary",
  textDecoration: "none",
  fontSize : '$1',
  fontWeight :  '400',
  color: "$footerLinks",
  "&:hover": {
    color: "#cccccc",
  },
});

export const BrandBox = styled("div", {
  display: "flex",
  maxWidth: "15em",
  flexWrap: "wrap",
  justifyContent: "space-between",
  a: {
    margin: "0em 2em 1em 0",
  },

  "@media only screen and (max-width : 600px)": {
    padding: 0,
    display: "block",
  },
});

export const ProdBox = styled("div", {
  "@media only screen and (max-width : 600px)": {
    padding: 0,
  },
});

export const Image = styled("img", {
  width: "137px",
  margin: "auto 0em",
});
