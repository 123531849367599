import React, { useState, useEffect } from "react";
import * as Icons from "react-feather";
// import { ThemeProvider } from "styled-components";
import * as S from "./Navigation.style.js";
import "./style.css";



const Navigation = ({Elements, nav_logo, companyName, theme }) => {
  const [menu, setMenu] = useState(false);

  const ToggleMenu = () => {
    setMenu((state) => !state);
  };

 

  const [isOpen, setIsOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setSticky(true);
    } else if (window.scrollY < 90) {
      setSticky(false);
    }
  };
  return (
    <>
      <div className={`header${sticky ? "-sticky" : ""}`}>
        {/*Navigation Bar */}
        <S.Nav
          style={{
            backgroundColor: sticky ? "#f0eeec" : "transparent",
            boxShadow: sticky ? "0px 4px 44px rgba(0, 0, 0, 0.08)" : "none",
          }}
        >
          <S.Container>
            <S.Icon>
              <S.Image href="#home" src={nav_logo} />
              {/* <S.Header>{companyName}</S.Header> */}
            </S.Icon>
            <S.MenuButton onClick={ToggleMenu}>
              <Icons.Menu color="#5A606B" />
            </S.MenuButton>
            <S.LinkContainer>
              {Elements.map((value, key) => {
                return <S.Link href={value.link}>{value.title} </S.Link>;
              })}

              <S.ContactButton href="/#contact">Contact us</S.ContactButton>
            </S.LinkContainer>
          </S.Container>
        </S.Nav>

        {/*Menu Box for small size screens*/}
        <S.MenuContainer className={menu ? "" : "clear"}>
          <S.Menu className="menuBox">
            {/* <S.Cross onClick = {ToggleMenu}>
            <Icons.XCircle color='#5A606B'/>
          </S.Cross> */}
            {Elements.map((value, key) => (
              <S.MenuLink key={key} href={value.link} onClick={ToggleMenu}>
                {value.title}
              </S.MenuLink>
            ))}
            <S.ContactButton href="/#contact" onClick={ToggleMenu}>
              Contact us
            </S.ContactButton>
          </S.Menu>
        </S.MenuContainer>
      </div>
      {/* <ThemeProvider>

    </ThemeProvider> */}
    </>
  );
};

export default Navigation;
