import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Animation from "react-animate-on-scroll";

import * as S from "./About.style.js";
import globalStyle from "../../styles/global.style";

const About = (data) => {
  globalStyle();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
  };

  return (
    <Animation animateIn="animate__fadeInUp" animateOnce={true}>
      <S.Container
        style={{
          backgroundImage: 'url("' + data.about.aboutBack + '")',
        }}
        id="about"
      >
        <S.Content className="hideMedium">
          <S.Image src={data.about.aboutFore} />
        </S.Content>
        <S.Box className="hideMedium">
          <S.Header>{data.about.heading}</S.Header>
          {/* <S.Text>{data.about.mainText}</S.Text> */}
          <S.Text>
            AMERINDIAN is a leading lifestyle home decor and accent furniture
            manufacturing export house. It has risen as a renowned brand by
            fusing international tastes with authentic Indian craftsmanship.
            <br />
            <br />
            Our products have impeccable detailing and design that goes into the
            making of our extensive variety of wooden boxes, candle holders,
            paper racks, coaster sets, wooden jewellery, door handles, curtain
            holders, nautical tables, photo frames, kitchenware, and much more.
          </S.Text>
        </S.Box>

        <S.Box className="showMedium">
          <S.Header>{data.about.heading}</S.Header>
          <S.Text>
            AMERINDIAN is a leading lifestyle home decor and accent furniture
            manufacturing export house. It has risen as a renowned brand by
            fusing international tastes with authentic Indian craftsmanship.
            <br />
            Our products have impeccable detailing and design that goes into the
            making of our extensive variety of wooden boxes, candle holders,
            paper racks, coaster sets, wooden jewellery, door handles, curtain
            holders, nautical tables, photo frames, kitchenware, and much more.
          </S.Text>
        </S.Box>
        <S.Content className="showMedium">
          <S.Image src={data.about.aboutFore} />
        </S.Content>
      </S.Container>
    </Animation>
  );
};

export default About;
