import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  width: "auto",
  maxHeight: '1900px',
  backgroundRepeat: "no-repeat",
  // zIndex: -,
  margin: "2em 0",
  "@media only screen and (max-width : 750px)": {
    textAlign: "justify",
    padding: "1em 0em",
    flexDirection: "column",
  },
});

export const Box = styled("div", {
 
  display: "flex",
  // margin: "10em 2em",
  width: "50%",
  flexDirection: "column",
  maxWidth : '800px',
  margin : '-5em 2em 0em 2em',
  "@media only screen and (max-width : 750px)": {
    padding: "2em 2em 0 2em",
    margin: "-3em 2em 0em 2em",
    width: "100%",
    alignItems: "flex-start",
    
  },
});



export const Header = styled("h1", {
  color: "rgba(255, 255, 255, 1)",
  paddingTop: "1em",
  fontFamily: "$font",
  fontSize : '48px',
  "@media only screen and (max-width : 750px)": {
    marginTop: "0em",
    fontSize: "$8",
  },
});

export const Text = styled("p", {
  padding: "2em 0 0 0",
  color: "$bodyInverted",
  lineHeight: "32px",
  fontSize : '18px',
  fontFamily: "$secondary",
  "@media only screen and (max-width : 750px)": {
    textAlign: "justify",
    padding: "1em 0em",
  },
});

export const Image = styled("img", {
  padding: "1em 4em",
    width: "546px",
  "@media only screen and (max-width : 750px)": {
    width: "100%",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "5em 0 0 0",
  width: "50%",
  margin: "0 auto",
  "@media only screen and (max-width : 750px)": {
    flexDirection: "column",
    width: "100% !important",
    padding: "2em 1em",
  },
});

export const Video = styled("video", {});
