import "./App.css";
import globalStyle from "./styles/global.style.js";
import "animate.css/animate.min.css";

import { Layout } from "./styles/theme.config.js";
import Navigation from "./components/Navigation/Navigation";
import Hero from "./components/Hero/Hero";
import Products from "./components/Products/Product";
import About from "./components/About/About";
import Description from "./components/Description/Description";
import Sustainability from "./components/Sustainability/Sustainability";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

import data from "./static/data.js";

import HeroBackground from "./components/HeroBackground/HeroBackground";

const App = () => {
  globalStyle();
  return (
    <>
      <Navigation {...data} />
      <HeroBackground {...data} />

      <Layout>
       

        <Hero {...data} />

        <Products {...data} />

        <About {...data} />

        <Description {...data} />

        {/* <Artisans data={data.artisansData} /> */}

        <Sustainability {...data} />
        {/* <ImageGallery {...data} /> */}
        <Contact {...data} />
      </Layout>
      <Footer {...data} />
    </>
  );
};

export default App;
