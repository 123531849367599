import { createStitches } from "@stitches/react";

export const { styled, css, globalCss, getCssString, theme, keyframes } =
  createStitches({
    theme: {
      colors: {
        black: "#030303",
        white: "#FAFAFD",
        primary: "#363E3D",
        primary_light: "#F34E6D",
        secondary: "#37958F",
        font: "#292C3E",
        grey: "#1D2624",
        button: "rgb(137, 126, 122)",
        bodyColor: "rgba(0, 0, 0, 0.6)",
        bodyInverted: "rgba(253, 253, 253, 1)",
        footerBg: "#414141",
        companyName : '#000000',
        footerLinks : '#E6E7E7',
        primaryBlack : ' #000000',
        link : '#4F5655',
        note : 'rgba(0, 0, 0, 0.6)',
        desc : 'rgba(0, 0, 0, 0.8)',
      },

      fonts: {
        font: "Junge",
        code: "monospace",
        secondary: "Source Sans Pro",

      },
      fontSizes: {
        1: "14px",
        2: "16px",
        3: "20px",
        4: "24px",
        5: "36px",
        6: "48px",
        7: "54px",
        8: "22px",
      },

      fontWeights : {
        1 : "400",
        2 : "700",
        3 : "900",

      }
    },
  });

export const Layout = styled("div", {
  position: "relative",
  overflow: "hidden",
  maxWidth: "1440px",
  margin: "0 auto",
});
