import * as S from "./Contact.style.js";
import { useState } from "react";
import Animation from "react-animate-on-scroll";
import { Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Mailgun from "mailgun.js";
import "./contact.css";
import "react-toastify/dist/ReactToastify.css";
import formData from "form-data";

const mailgun = new Mailgun(formData);
const mg = mailgun.client({
  username: "api",
  key: process.env.REACT_APP_MAILGUN,
});

const Contact = ({ emails, companyName }) => {

   const [loading , setLoading] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    company_name: "",
    email: "",
    mobile_number: "",
    message: "",
  });

  const onSubmit = (e) => {
      setLoading(true);
    e.preventDefault();

    mg.messages
      .create("mail.sourcewiz.co", {
        from: `${companyName} via Sourcewiz <no-reply@sourcewiz.co>`,

        to: emails,
        subject: "New Enquiry from Our Website",
        bcc: [
          "mayur@sourcewiz.co",
          "mayur.sonawane@sourcewiz.co",
          "ashmit@sourcewiz.co",
        ],
        html: `<div>
          <h5>Name: ${toSend?.from_name} </h5>
             const Contact = () => {
                 <h5>Message: ${toSend?.message}</h5>
          </div>`,
      })

      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setLoading(false);
        toast.success(
          "Email sent successfully",

          { position: toast.POSITION.BOTTOM_RIGHT }
        );
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Animation animateIn="animate__fadeInUp" animateOnce={true}>
      <S.Container id="contact">
        <S.Content>
          <S.Header>Send us an enquiry</S.Header>

          <S.Text>
            We will get back to address your enquiry within 24 hours.
          </S.Text>

          <S.FormContainer autoComplete="off" method="POST" onSubmit={onSubmit}>
            <S.Row>
              <S.Box>
                <S.InputHeading>Your Name *</S.InputHeading>
                <S.Input
                  type="text"
                  name="from_name"
                  required
                  value={toSend.from_name}
                  onChange={handleChange}
                />
              </S.Box>
              <S.Box>
                <S.InputHeading>Company *</S.InputHeading>
                <S.Input
                  type="text"
                  name="company_name"
                  required
                  value={toSend.company_name}
                  onChange={handleChange}
                />
              </S.Box>
            </S.Row>

            <S.Row>
              <S.Box>
                <S.InputHeading>Email ID *</S.InputHeading>
                <S.Input
                  type="email"
                  name="email"
                  required
                  value={toSend.email}
                  onChange={handleChange}
                />
              </S.Box>
              <S.Box>
                <S.InputHeading>Contact number *</S.InputHeading>
                <S.Input
                  type="tel"
                  name="mobile_number"
                  required
                  value={toSend.mobile_number}
                  onChange={handleChange}
                />
              </S.Box>
            </S.Row>

            <S.Row>
              <S.Box>
                <S.InputHeading>Message (Optional)</S.InputHeading>
                <S.TextArea
                  name="message"
                  value={toSend.message}
                  onChange={handleChange}
                />
              </S.Box>
            </S.Row>

            <S.Row>
              {loading ? (
                <Button className="loading__btn" loading={loading}>
                  Submit query
                </Button>
              ) : (
                <S.Button as="button" type="submit" value="send">
                  Submit query
                </S.Button>
              )}
            </S.Row>
          </S.FormContainer>
        </S.Content>
      </S.Container>
      <ToastContainer />
    </Animation>
  );
};

export default Contact;
