import { ThemeProvider } from "styled-components";
import * as S from "./HeroBackground.style.js";

const HeroBackground = (props) => {
  const { background, foreground, mainText, subText, type = "normal" } = props;
  const theme = {
    Header: {
      font: "100px",
    },
  };
 
  return (
    <ThemeProvider theme={theme}>
      <S.Container
        type={type}
        style={{
          backgroundImage: 'url("' + background + '")',
        }}
        id="home"
      >
        <S.Content>
          <S.Header type={type} className="heading">
            {mainText}
          </S.Header>
          <S.Para>{subText}</S.Para>
          <S.Button href="/#products">View products</S.Button>
        </S.Content>
        <S.Image src={foreground} />
      </S.Container>
    </ThemeProvider>
  );
};

export default HeroBackground;
